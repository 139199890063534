@import "../node_modules/@syncfusion/ej2-material-theme/styles/material.css";
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// regular style toast
@import "~ngx-toastr/toastr";

// @font-face {
//   font-family: "STCFont";
//   src: url("./assets/fonts/stc/STCForwardRegular.ttf");
//   src: url("./assets/fonts/stc/STCForwardBold.ttf");
// }
@font-face {
  font-family: "STCFont";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  font-size: 12px;
  src: url("./assets/fonts/stc/STC\ Forward\ Regular.ttf");
  src: url("./assets/fonts/stc/STC\ Forward\ Bold.ttf");
}

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

body {
  font-family: "STCFont", "sans-serif";
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.modal-backdrop {
  z-index: 1050 !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 95px;
  height: 95px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #2c3e50;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2c3e50 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cdk-overlay-container {
  z-index: 2000 !important;
}
